@import "~antd/dist/antd.css";

.logo {
  font-size: large;
  font-weight: bold;
}

.header-style {
  padding: 0 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.site-layout-background {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;

  padding: 10px;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 28px;
}
/* .ant-menu-inline-collapsed{
    
    padding: 0 28px;
  
} */

.site-calendar-card {
  width: 286px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  margin-bottom: 20px;
}

.current-rezervations {
  margin: 10px;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.time-select {
  display: flex;
  flex-direction: column;
}
.drying-room {
  display: block;
}

.mb-10px {
  margin-bottom: 10px;
}

.time-strings {
  display: flex;
  justify-content: space-around;
  font-weight: "regular";
}

.login-block {
  background-color: #f0f0f0b7;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
}

.login-text {
  /*  style={{display:'flex', flexDirection: 'column', alignItems: 'center'}} */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.login-form {
  border-radius: 5px;
  box-shadow:rgba(0,0,0,0.14902) 0px 1px 1px 0px, rgba(0,0,0,0.09804) 0px 1px 2px 0px;
  margin-top: 20px;
  background-color: #ffffff;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  padding-top: 40px;
}
